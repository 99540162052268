import * as React from "react";

import ContactMeForm from "../components/ContactMeForm/ContactMeForm";
import SEO from "../components/SEO";
import styled from "styled-components";

export const ContactUsRow = styled.section`
  padding-bottom: 120px;
  background-color: #1e1d1c;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Col = styled.div`
  width: 50%;
  text-align: center;
  padding-top: 10%;
  padding-right: 5%;
  padding-bottom: 10%;
  padding-left: 5%;
  color: var(--white);

  h2 {
    /* font-family: 'Playfair Display', Georgia, 'Times New Roman', serif; */
    font-size: 36px;
    padding-bottom: 10px;
  }

  p {
    /* font-family: 'Muli', Helvetica, Arial, Lucida, sans-serif; */
    font-size: 18px;
    line-height: 1.8em;
    margin-top: 25px;
    word-wrap: break-word;
    color: rgba(255, 255, 255, 0.7);
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 980px) {
    width: initial;
    padding-right: 10%;
    padding-left: 10%;

    p {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Divider = styled.div`
  & {
    border: 1px solid var(--white);
    display: inline-block;
    width: 12%;
    border-width: 2px;
  }
`;

export default function contactUs() {
  return (
    <main>
      <SEO title="Contact Me" />
      <ContactUsRow>
        <Col style={{ paddingBottom: "3%" }}>
          <h2>Got a quick question for me?</h2>
          <Divider />
          <div style={{ padding: "3% 3%" }}>
            <a
              style={{ color: "rgba(203, 171, 94, 1)" }}
              href="tel:+447545277074"
            >
              +447545277074
            </a>
          </div>

          <div>
            Want to learn more about how we can elevate your home with our
            plastering and decorating services? We're here to help! Fill out our
            quick and easy contact form, or send us an email and we'll get back
            to you promptly. Whether you have a specific project in mind or
            simply want to ask a question, we're here to provide expert guidance
            and assistance. Don't hesitate, let's start the conversation today!
          </div>
          <div style={{ padding: "3% 3%" }}>
            <address>
              <a
                style={{ color: "rgba(203, 171, 94, 1)" }}
                href="mailto:info@alexdecor.co.uk"
              >
                info@alexdecor.co.uk
              </a>
            </address>
          </div>
        </Col>
        <ContactMeForm />
      </ContactUsRow>
    </main>
  );
}
